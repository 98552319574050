import { Link, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import MenuLink from 'components/MenuLink'
import { INSTAGRAM_PROFILE_LINK } from 'utils/config'

const MAIN_PATH = ''
const CONTACT_PATH = 'contact'
const PROJECTS_PATH = 'projects'
const HOME_PATH = 'home'

const Header = () => {
  const location = useLocation()

  const activePage = useMemo(
    () => location.pathname.substring(1),
    [location.pathname]
  )

  return (
    <div className="md:flex md:justify-between md:items-center md:justify-items-center md:py-8">
      <div className="sm:max-md:mx-auto align-center">
        <Link to="/">
          <img
            className="w-full md:w-auto size-32"
            src="/assets/logo.svg"
            alt="izdecoration"
          />
        </Link>
      </div>
      <div className="sm-max-sm:w-full flex grow md:grow-0 justify-center gap-6 py-8 font-medium">
        <MenuLink path="/home" isActive={activePage === HOME_PATH}>
          L'Agence
        </MenuLink>
        <MenuLink path="/" isActive={activePage === MAIN_PATH || activePage.startsWith(PROJECTS_PATH)}>
          Réalisations
        </MenuLink>
        <MenuLink path="/contact" isActive={activePage === CONTACT_PATH}>
          Contact
        </MenuLink>
        <MenuLink path={INSTAGRAM_PROFILE_LINK} openInNewTab>
          <FontAwesomeIcon className="fa-xl" icon={faInstagram} />
        </MenuLink>
      </div>
    </div>
  )
}

export default Header
